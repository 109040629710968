import React from 'react';
import {
  Grid, Image
} from 'semantic-ui-react';

import Main from '../layouts/Main';
import { asset } from '../lib/Util';
import Config from '../lib/Config';
import { __ } from '../lib/I18n';

import { getNodeEnv } from '../lib/env';

const App = () => (
  <Main noHeader className="landing-root">
    <div className="landing-hero-container">
      <div
        style={{
          backgroundImage: `
            linear-gradient(to bottom, rgba(177, 42, 42, 0.7) 0%, rgba(177, 42, 42, 0.7) 100%),
            url('${asset('background-landing.jpg')}')`,
        }}
        className="hero"
      >
        <Image src={asset('logo-branco-v.png')} />
        <span>
          {__('A ferramenta que te ajuda a monitorar a saúde da sua escola')}
        </span>
        <a className="landing-header-button" href={Config.loginRedirect[getNodeEnv()]}>{__('Acessar')}</a>
      </div>
      <Grid columns={3} stackable className="landing-grid">
        <Grid.Column>
          <Image src={asset('survey.png')} />
          <div className="container">
            <span className="title">{__('Envio de enquetes pelo ClassApp')}</span>
            <span className="subtitle">
              {__('Envie enquetes para os pais periodicamente de forma automática')}
            </span>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Image src={asset('collaborate.png')} className="middle" />
          <div className="container">
            <span className="title">
              {__('Contribua no monitoramento')}
            </span>
            <span className="subtitle">
              {__('Acompanhe a saúde de sua escola e contribua para o monitoramento do bem-estar social')}
            </span>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Image src={asset('monitoring.png')} />
          <div className="container">
            <span className="title">
              {__('Monitore a saúde de sua escola')}
            </span>
            <span className="subtitle">
              {__('Acompanhe informações relevantes sobre seus alunos')}
            </span>
          </div>
        </Grid.Column>
      </Grid>
      <div className="footer" />
    </div>
  </Main>
);

export default App;
